import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@infra-env/environment';
declare const Pusher: any;

@Injectable({
  providedIn: 'root'
})
export class PusherNotificationService {
  private pusher: any;
  private channel: any;

  constructor(
    private readonly http: HttpClient,
  ) {
    this.pusher = new Pusher(environment.pusher.key, {
      cluster: environment.pusher.cluster,
    });
  }

  getChannel() {
    return this.channel;
  }

  subscribeToChannel(channelName: string) {
    if (this.channel?.name === channelName) {
      return;
    }
    this.unsubscribeFromChannel();
    this.channel = this.pusher.subscribe(channelName);
    console.log(`Suscrito a: ${channelName}`);
  }

  unsubscribeFromChannel() {
    if (this.channel) {
      this.pusher.unsubscribe(this.channel.name);
      console.log(`Desuscrito de: ${this.channel.name}`);
      this.channel = null;
    }
  }
}